import toast from "react-hot-toast";
import userStore from "./../mobx/userStore";

export const axiosErrorHandler = (e) => {

    if(typeof e.response !== "undefined"){

        if(typeof e.response.data.type !== "undefined" && e.response.data.code === 401 && e.response.data.type === "INVALID_TOKEN"){
            toast.error("Invalid token");
            userStore.logout().then();
            return false;
        }

        const errorText = typeof e.response.data.message !== "undefined" ? e.response.data.message : e.response.statusText;
        toast.error(typeof errorText === "string" ? errorText :JSON.stringify(errorText));
    }
    console.error(e);
}
