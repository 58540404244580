import {Outlet} from "react-router-dom";
import MainMenu from "./MainMenu/MainMenu";

const DashboardRoot = (props) => {
    return (
        <div className="flex">
            <div>
                <MainMenu/>
            </div>
            <div className="p-4 w-full">
                <Outlet/>
            </div>
        </div>
    )
}

export default DashboardRoot;