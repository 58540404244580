const addZero = (month) => {
    return (month > 9) ? month : `0${month}`;
}
export const convertTimestampToDate = (timestamp) => {
    if(!timestamp){
        return "Never";
    }
    const date = new Date(+timestamp);
    return `${addZero(date.getDate())}-${addZero(date.getMonth()+1)}-${date.getFullYear()} ${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
}


export const convertTimestampAgoToWords = (timestamp) => {
    if(!timestamp){
        return "Never";
    }
    let currentTimestamp = Math.round(+(new Date()));
    let time_left = currentTimestamp - timestamp;
    return `${convertMsToWords(time_left)} ago`;
}

export const convertMsToWords = (time) => {
    time = +time;
    if(!time){
        return "Never";
    }
    if(time > 86400000){
        const days = Math.floor(time / 86400000);
        if (days === 1){
            return `${days} day`;
        }
        return `${days} days`;
    }else if(time > 3600000){
        const hours  = Math.floor(time / 3600000);
        if (hours === 1){
            return `${hours} hour`;
        }
        return `${hours} hours`;
    }else{
        const minutes  = Math.floor(time / 60000);

        if (minutes === 1){
            return `${minutes} minute`;
        }
        return `${minutes} minutes`;
    }
}