import {makeAutoObservable} from "mobx";
import api from "../api/api";
import toast from "react-hot-toast";



class ImagesLibraryStore {
    constructor() {
        makeAutoObservable(this);
    }
    initialized = false;

    setInitialized = (value) => {
        this.initialized = value;
    }

    imagesList = [];

    setImagesList = (value) => {
        this.imagesList = value;
    }

    addNewImageToImagesList = (imageObject) => {
        this.imagesList.push(imageObject)
    }

    uploadFile = async (formData) => {
        const res = await api.uploadImage(formData);
        if(res){
            toast.success("File Uploaded", {position: 'top-right'});
            this.addNewImageToImagesList(res[0]);
        }
    }

    initImagesList = async () => {
        const res = await api.getUploadedImagesList();
        if(res){
            this.setInitialized(true);
            this.setImagesList(res);
        }
    }

}

const imagesLibraryStore = new ImagesLibraryStore();

export default imagesLibraryStore;