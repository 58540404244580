import {observer} from "mobx-react-lite";
import {useStores} from "../../../store-context";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as React from "react";
import Loader from "../Loader/Loader";
import {Button, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import Paper from "@mui/material/Paper";

const SettingsPage = observer(() => {
    const {
        dashboard: {
            internalOptions,
            refreshDataLoading,
            refreshData,
            sitesCount,
            init,
            setInternalOptions,
            saveInternalOptions
        }
    } = useStores();

    if (sitesCount === null) {
        init();
        return <Loader/>
    }

    return <Paper className="rounded-lg">
        <div className="grid grid-cols-1 gap-4 p-4">
            <FormControlLabel
                control={<Switch checked={!!+(internalOptions.autoposting_enabled)} onChange={(event) => {
                    setInternalOptions({...internalOptions, autoposting_enabled: event.target.checked ? 1 : 0});
                }}/>}
                label="Autoposting Enabled"
            />

            <TextField label="Autoposting Interval" variant="outlined" type="number"
                       value={internalOptions.autoposting_interval} onChange={(e) => {
                setInternalOptions({...internalOptions, autoposting_interval: e.target.value})
            }}/>
            <TextField label="Autoposting Count Sites In One Cycle" variant="outlined" type="number"
                       value={internalOptions.autoposting_count_sites_in_cycle} onChange={(e) => {
                setInternalOptions({...internalOptions, autoposting_count_sites_in_cycle: e.target.value})
            }}/>
        </div>
        <div className="p-4">
            <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFloppyDisk} size="sm"/>}
                    onClick={saveInternalOptions}>
                Save
            </Button>
        </div>


    </Paper>
})

export default SettingsPage;