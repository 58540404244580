import {useParams} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store-context";
import Loader from "../Loader/Loader";
import {useEffect} from "react";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {Button} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import ArticleEditorForm from "./ArticleEditorForm";

const ArticleEditorPage = observer(() => {
    const {articleEditor: {loadArticleToEditor, dataLoadingProcess, error, articleData, updateArticleData, updateArticle}} = useStores();
    const {id} = useParams();

    useEffect(() => {
        loadArticleToEditor(+id);
    }, [id, loadArticleToEditor]);

    if (dataLoadingProcess) {
        return <Loader/>;
    }

    if (error) {
        return <div>Error</div>;
    }

    return <div>

        <h1 className="font-bold text-2xl mb-4">Edit article {articleData.id}</h1>


        <Paper className="rounded-lg">

            <div className="p-4 flex justify-between">
                <FormControlLabel
                    control={<Switch checked={!!articleData.published} onChange={(event) => {updateArticleData({published: event.target.checked ? 1 : 0});}}/>}
                    label="Published"
                />

                <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFloppyDisk} size="sm" />} onClick={updateArticle}>
                    Save
                </Button>
            </div>


            <ArticleEditorForm articleData={articleData} updateArticleData={updateArticleData} />

        </Paper>


    </div>
})


export default ArticleEditorPage;