import {observer} from "mobx-react-lite";
import {useStores} from "../../../store-context";
import {useEffect} from "react";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {Button} from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import ArticleEditorForm from "./../ArticleEditorPage/ArticleEditorForm";
import {useNavigate} from "react-router-dom";


const AddArticlePage = observer(() => {

    const {addArticle: {articleData, updateArticleData, createArticle, setEmptyData}, articles: {initArticlesList}} = useStores();
    const navigate = useNavigate()

    useEffect(() => {
        setEmptyData();
    }, [setEmptyData]);

    const createArticleHandler = async () => {
        const newArticleId = await createArticle();
        if(newArticleId){
            setEmptyData();
            initArticlesList();
            navigate(`/articles/edit/${newArticleId}`);
        }
    }

    return <div>

        <h1 className="font-bold text-2xl mb-4">Edit article {articleData.id}</h1>


        <Paper className="rounded-lg">

            <div className="p-4 flex justify-between">
                <FormControlLabel
                    control={<Switch checked={!!articleData.published} onChange={(event) => {updateArticleData({published: event.target.checked ? 1 : 0});}}/>}
                    label="Published"
                />

                <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFloppyDisk} size="sm" />} onClick={createArticleHandler}>
                    Create
                </Button>
            </div>


            <ArticleEditorForm articleData={articleData} updateArticleData={updateArticleData} />

        </Paper>


    </div>
})


export default AddArticlePage;