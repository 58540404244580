import {observer} from "mobx-react-lite";
import {TextField} from "@mui/material";
import Editor from "./Editor";
import * as React from "react";
import ImagePicker from "./ImagePicker/ImagePicker";
import {useState} from "react";

const ArticleEditorForm = observer(({articleData, updateArticleData}) => {

    const [imagePickerVisibility, setImagePickerVisibility] = useState(false);

    const toggleImagePickerVisibility = () => {
        setImagePickerVisibility(!imagePickerVisibility);
    }

    const setContent = (value) => {
        updateArticleData({content: value});
    }

    return <>
        <div className="grid grid-cols-1 gap-4 p-4">
            <TextField label="Title" variant="outlined" value={articleData.title} onChange={(e) => {updateArticleData({title: e.target.value})}}/>
            <TextField label="Description" variant="outlined" value={articleData.description} onChange={(e) => {updateArticleData({description: e.target.value})}}/>
            <TextField label="Excerpt" variant="outlined" value={articleData.excerpt} onChange={(e) => {updateArticleData({excerpt: e.target.value})}}/>
        </div>

        <div className="grid grid-cols-2 gap-4 p-4 pt-0">
            <TextField label="URL" variant="outlined" value={articleData.url} onChange={(e) => {updateArticleData({url: e.target.value})}}/>
            <TextField label="views" variant="outlined" type="number" value={articleData.views} onChange={(e) => {updateArticleData({views: e.target.value})}}/>
        </div>
        <div className="grid grid-cols-2 gap-4 p-4 pt-0">

            <TextField label="Image" variant="outlined" type="text" value={articleData.image_path} onChange={(e) => {updateArticleData({image_path: e.target.value})}}/>
            <img src={`${process.env.REACT_APP_MAIN_FRONT_ENDPOINT}${articleData.image_path}`} className="h-14 cursor-pointer" alt="Click to change" onClick={toggleImagePickerVisibility} />
            {!imagePickerVisibility || <ImagePicker initialImagePath={articleData.image_path} setImageCallback={(value) => {updateArticleData({image_path: value})}} closeHandler={toggleImagePickerVisibility} />}
        </div>

        <div className="grid grid-cols-2 gap-4 p-4">
            <TextField label="created_at" variant="outlined" type="datetime-local" value={new Date(articleData.created_at).toISOString().slice(0, 19)} onChange={(event) => {updateArticleData({created_at: +(new Date(event.target.value))})}} />
            <TextField label="updated_at" variant="outlined" type="datetime-local" value={new Date(articleData.updated_at).toISOString().slice(0, 19)} onChange={(event) =>{updateArticleData({updated_at: +(new Date(event.target.value))})}} />
        </div>


        <div className="p-4">
            <Editor content={articleData.content} setContent={setContent}/>
        </div>
    </>
});

export default ArticleEditorForm;

//${process.env.REACT_APP_FILES_ENDPOINT}