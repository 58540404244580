import {makeAutoObservable} from "mobx";
import api from "../api/api";
import toast from "react-hot-toast";


class AddArticleStore{
    constructor() {
        makeAutoObservable(this);
    }


    articleData = {
        title: "",
        url: "",
        description: "",
        published: 0,
        excerpt: "",
        image_path: "",
        content: "",
        created_at: +(new Date()),
        updated_at: +(new Date()),
        views:0,
    };

    setEmptyData = () => {
        this.articleData = {
            title: "",
            url: "",
            description: "",
            published: 0,
            excerpt: "",
            image_path: "",
            content: "",
            created_at: +(new Date()),
            updated_at: +(new Date()),
            views:0,
        }
    }

    updateArticleData = (valueObject) => {
        this.articleData = {...this.articleData, ...valueObject};
    }

    createArticle = async () => {
        const res = await api.createArticle(this.articleData);
        if(res){
            toast.success("Success", {position: 'top-right'});
            return res;
        }
    }


}

const addArticleStore = new AddArticleStore();

export default addArticleStore;