import {observer} from "mobx-react-lite";
import {useStores} from "../../../../store-context";
import Loader from "../../Loader/Loader";
import toast from "react-hot-toast";
import * as React from "react";
import UploadFileDragDrop from "./../UploadFileDragDrop/UploadFileDragDrop";
import "./imagePicker.css";
import {Paper} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const ImagePicker = observer(({initialImagePath, setImageCallback, closeHandler}) => {
    const {imagesLibrary: {initialized, initImagesList, imagesList, uploadFile}} = useStores();

    const handleUpload = (file) => {
        if (!file) {
            return toast.error("No image selected");
        }
        const formData = new FormData();
        formData.append(file.name, file);
        uploadFile(formData);
    };

    const handleChooseImage = (pathUrl) => {
        setImageCallback(pathUrl);
        closeHandler();
    }

    if (!initialized) {
        initImagesList();
        return <Loader/>
    }

    return <Paper elevation={3} className="image-picker">
        <div className="border-b h-12 flex justify-between items-center pl-3">
            <span>Image Picker</span>
            <Tooltip title="Delete" className="flex-shrink-0 w-10">
                <IconButton onClick={closeHandler}>
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            </Tooltip>

        </div>
            <div className="w-full flex flex-col p-4 image-picker-content">
                <div className="flex w-full">
                    <div className="w-1/3 h-52 flex justify-center items-center">
                        <img src={`${process.env.REACT_APP_MAIN_FRONT_ENDPOINT}${initialImagePath}`} alt="data" className="max-h-52 w-auto"/>
                    </div>
                    <div className="w-2/3">
                        <UploadFileDragDrop setFile={handleUpload} fileTypes={["JPEG", "WEBP", "PNG", "GIF", "JPG"]}/>
                    </div>
                </div>
                <div>
                    <div className="w-full pt-6">
                        <ul className="grid grid-cols-6 gap-4">
                            {imagesList.map(el => {
                                return <li className="flex flex-col cursor-pointer border justify-center" onClick={() => {
                                    handleChooseImage(el.pathUrl)
                                }} key={el.pathUrl}>
                                    <img src={`${process.env.REACT_APP_MAIN_FRONT_ENDPOINT}${el.pathUrl}`} alt="data"/>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </Paper>


})

export default ImagePicker;