import Loader from "../Loader/Loader";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store-context";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertTimestampToDate} from "../../../utils/timeConvert";
import {NavLink} from "react-router-dom";


const ArticlesTable = observer(() => {

    const {articles: {initArticlesList, articles, setArticlePublishState, deleteArticle}} = useStores();

    if (articles.length === 0) {
        initArticlesList();
        return <Loader/>;
    }

    return (
        <>
            <h1 className="font-bold text-2xl mb-4">Articles</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size="medium" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Views</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {articles.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                className={row.published ? "bg-green-50" : "bg-gray-50"}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.url}</TableCell>
                                <TableCell>{convertTimestampToDate(row.created_at)}</TableCell>
                                <TableCell>{convertTimestampToDate(row.updated_at)}</TableCell>
                                <TableCell>{row.views}</TableCell>
                                <TableCell>

                                    <NavLink to={`edit/${row.id}`}>
                                        <Tooltip title="Edit">
                                            <IconButton>
                                                <FontAwesomeIcon icon={faPenToSquare} size="xs"/>
                                            </IconButton>
                                        </Tooltip>
                                    </NavLink>

                                    {row.published ?
                                        <Tooltip title="Unpublish">
                                            <IconButton onClick={() => {
                                                setArticlePublishState(row, false)
                                            }}>
                                                <VisibilityOffIcon/>

                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Publish">
                                            <IconButton onClick={() => {
                                                setArticlePublishState(row, true)
                                            }}>
                                                <VisibilityIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }


                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => {deleteArticle(row)}}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
});

export default ArticlesTable;