import axios from "axios";
import {axiosErrorHandler} from "./errorHandler";
import Cookies from 'js-cookie';

class Api{

    constructor(baseUrl, AxiosErrorHandler = null) {
        this.axiosInstance = axios.create({
            withCredentials: true,
            baseURL: baseUrl
        })
        this.axiosErrorHandler = AxiosErrorHandler ? AxiosErrorHandler : (e) => console.error(e);
    }

    _request = async (method, url, data = null, additionalHeaders = null) => {

        const headers = {Authorization: `Bearer ${Cookies.get("userToken")}`, ...additionalHeaders};
        try{
            const result = await this.axiosInstance.request({
                method: method,
                url: url,
                data,
                headers
            });
            return await result.data;
        }catch (e){
            this.axiosErrorHandler(e);
            return false;
        }
    }

    loginUser = async (username, password) => {
        return await this._request('post', '/login',{username, password});
    }

    validateToken = async (userToken) => {
        return await this._request('post', '/validate-token',{token:userToken});
    }

    getSitesList = async () => {
        return await this._request('get', '/list-sites');
    }

    deleteSite = async (id) => {
        return await this._request('post', '/delete-site',{id});
    }
    deleteSiteFromAutopostingQueue = async (id) => {
        return await this._request('post', '/delete-site-from-autoposting',{id});
    }
    updateSite = async (id, data) => {
        return await this._request('post', '/update-site',{id, data});
    }

    addSites = async (sitesArray) => {
        return await this._request('post', '/add-sites-directly', {sites:sitesArray});
    }

    recreateSitemap = async () => {
        return await this._request('get', '/recreate-sitemap');
    }

    clearFrontCache = async () => {
        return await this._request('get', '/clear-front-cache');
    }

    getCountFromTable = async (table) => {
        return await this._request('post', '/get-count-from-table', {table});
    }

    getInternalOptions = async () => {
        return await this._request('get', '/get-internal-options');
    }

    getListComments = async () => {
        return await this._request('get', '/get-comments-list');
    }

    deleteComment = async (id) => {
        return await this._request('post', '/delete-comment', {id});
    }

    getArticlesList = async () => {
        return await this._request('get', '/get-articles-list');
    }

    getArticleById = async (id) => {
        return await this._request('post', '/get-article-by-id', {id});
    }

    updateArticle = async (articleId, data) => {
        return await this._request('post', '/update-article', {articleId: articleId, data:data});
    }

    createArticle = async (articleData) => {
        return await this._request('post', '/create-article', {articleData});
    }

    deleteArticle = async (articleId) => {
        return await this._request('post', '/delete-article', {articleId});
    }

    uploadImage = async (formData) => {
        return await this._request('put', '/upload-image', formData, {
            'Content-Type': 'multipart/form-data',
        });
    }

    getUploadedImagesList = async () => {
        return await this._request('get', '/get-uploaded-images-list');
    }

    setAllSitesUrlsToLowerCase = async () => {
        return await this._request('get', '/set-all-urls-to-lowercase');
    }

    setInternalOptions = async (options) => {
        return await this._request('post', '/set-internal-options', {internal_options:options});
    }

    addSitesToAutoposting = async (sitesArray) => {
        return await this._request('post', '/add-sites-to-autoposting', {sites:sitesArray});
    }

    getSitesFromAutopostingQueue = async () => {
        return await this._request('get', '/get-sites-from-autoposting-queue');
    }

}

const api = new Api(process.env.REACT_APP_API_ENDPOINT, axiosErrorHandler);
export default api;