import {makeAutoObservable, runInAction} from "mobx";
import api from "../api/api";
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';


class userStoreClass {

    constructor() {
        makeAutoObservable(this);
    }

    username = "";
    password = "";
    isLoading = true;
    userToken = null;
    userAuthenticated = false;

    setUsername = (value) => {
        this.username = value
    }

    setPassword = (value) => {
        this.password = value
    }

    setIsLoading = (value) => {
        this.isLoading = value;
    }

    setUserAuthenticated = (value) => {
        this.userAuthenticated = value;
    }


    initUser = async () => {
        const token = Cookies.get("userToken");
        if(typeof token === "undefined"){
            this.setIsLoading(false);
            return false;
        }
        runInAction(async () => {

            const userdata = await api.validateToken(token);
            if(userdata){
                this.userToken = token;
                this.setUserAuthenticated(true);
                this.setIsLoading(false);
            }else{
                this.setUserAuthenticated(false);
                this.setIsLoading(false);
            }
        })

    }

    login = async () => {

        this.setIsLoading(true);
        const result = await api.loginUser(this.username, this.password);
        if(result){
            runInAction(() => {
                this.userToken = result.token;
                this.setIsLoading(false);
                Cookies.set("userToken", result.token, {expires: new Date(new Date().getTime() + 4 * 60 * 60 * 1000)});
                this.setUserAuthenticated(true);
                toast.success("Success");
            })
        }else{
            this.setIsLoading(false);
        }
    }

    logout = async () => {
        Cookies.remove('userToken');
        this.userToken = false;
        this.setUserAuthenticated(false);
        toast('Logged out');
    }

}
const userStore = new userStoreClass();

export default userStore;