import userStore from "./userStore";
import SitesStore from "./sitesStore";
import dashboardStore from "./dashboardStore";
import commentsStore from "./commentsStore";
import articlesStore from "./articlesStore";
import articleEditorStore from "./articleEditorStore";
import addArticleStore from "./addArticleStore";
import imagesLibraryStore from "./imagesLibraryStore";

class Store{
    user = userStore;
    sites = SitesStore;
    dashboard = dashboardStore;
    comments = commentsStore;
    articles = articlesStore;
    articleEditor = articleEditorStore;
    addArticle = addArticleStore;
    imagesLibrary = imagesLibraryStore;
}

export default Store;