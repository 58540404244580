import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import {observer} from "mobx-react-lite";
import {useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import * as React from "react";
import {TextareaAutosize} from "@mui/material";

const Editor = observer(({setContent, content}) => {

    const [visualEditor, setVisualEditor] = useState(true);

    const handleVisualEditor = (event) => {
        setVisualEditor(event.target.checked);
    }

    const handleChange = (value) => {
        setContent(value);
    }

    const modules = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return <div className="w-full">

        <div className="grid justify-items-end">
            <FormControlLabel
                control={<Switch checked={visualEditor} onChange={handleVisualEditor}/>}
                label="Visual Editor"
                labelPlacement="start"
            />
        </div>


        {visualEditor ?
            <ReactQuill value={content}
                        modules={modules}
                        formats={formats}
                        onChange={handleChange}/>
            :
            <TextareaAutosize  value={content}
                      rows={content.length > 30 ? Math.floor(content.length / 120) : 10}
                      className="block p-3 w-full text-sm text-gray-900 bg-white-50 border border-gray-300 outline-0 mb-4" onChange={ (e) => {handleChange(e.target.value)}}/>
        }


    </div>
});

export default Editor;