import {makeAutoObservable} from "mobx";
import api from "../api/api";
import toast from "react-hot-toast";

class CommentsStore{
    constructor() {
        makeAutoObservable(this);
    }

    comments = [];
    order = 'desc';
    orderBy = 'id';
    page = 0;
    rowsPerPage = 25;


    setComments = (value) => {
        this.comments = value;
    }

    setOrder = (value) => {
        this.order = value;
    }

    setOrderBy = (value) => {
        this.orderBy = value;
    }

    setPage = (value) => {
        this.page = value;
    }

    setRowsPerPage = (value) => {
        this.rowsPerPage = value;
    }

    initComments = async () => {
        const comments = await api.getListComments();
        if(comments){
            this.setComments(comments)
        }
    }

    deleteComment = async (id) => {
        const result = await api.deleteComment(id);
        if(result){
            this.setComments(this.comments.filter(el => el.id !== id));
            toast.success("Comment deleted", {position: 'top-right'});
        }
    }


}

const commentsStore = new CommentsStore();

export default commentsStore;