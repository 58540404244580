import {observer} from "mobx-react-lite";
import {useStores} from "../store-context";



const loginPage = observer(() => {


    const {user: {username, password, setPassword, setUsername, login}} = useStores();

    return <>
        <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
            <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
                <div className="px-5 py-7 text-left">
                    <label htmlFor="username"
                           className="font-semibold text-sm text-gray-600 pb-1 block">Username</label>
                    <input type="text" id="username" className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
                           value={username} onChange={(e) => {
                        setUsername(e.target.value)
                    }}/>

                    <label htmlFor="password"
                           className="font-semibold text-sm text-gray-600 pb-1 block">Password</label>
                    <input type="password" id="password"
                           className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" value={password}
                           onChange={(e) => {
                               setPassword(e.target.value)
                           }}/>

                    <button onClick={() => {login()}}
                        className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">Login</button>
                </div>
            </div>
        </div>

    </>
})

export default loginPage;