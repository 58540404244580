import {useStores} from "../../../store-context";
import MainMenuLink from "./MainMenuLinks/MainMenuLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faSliders} from '@fortawesome/free-solid-svg-icons';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const MainMenu = () => {
    const {user: {logout}} = useStores();
    return (
        <div className="flex flex-col items-center w-40 h-full overflow-hidden text-gray-400 bg-gray-900 rounded h-screen w-64 sticky top-0">
            <span className="flex items-center w-full px-4 mt-3">
                <span className="text-2xl"><FontAwesomeIcon icon={faCube} /></span>
                <span className="ml-2 text-sm font-bold">Upstime</span>
            </span>
            <div className="w-full px-2">
                <div className="flex flex-col items-center w-full mt-3 border-t border-gray-700">

                    <MainMenuLink path={'main'} name="Dashboard" icon={<FontAwesomeIcon icon={faHouse} />} />
                    <MainMenuLink path={'sites'} name="Sites" icon={<FontAwesomeIcon icon={faRectangleList} />} />
                    <MainMenuLink path={'autoposting-sites'} name="Autoposting" icon={<FontAwesomeIcon icon={faRectangleList} />} />
                    <MainMenuLink path={'add-sites'} name="Add Sites" icon={<FontAwesomeIcon icon={faPlus} />} />
                    <MainMenuLink path={'comments'} name="Comments" icon={<FontAwesomeIcon icon={faComment} />} />
                    <MainMenuLink path={'articles'} name="Articles" icon={<FontAwesomeIcon icon={faCopy} />} />
                    <MainMenuLink path={'add-article'} name="Add Article" icon={<FontAwesomeIcon icon={faPlus} />} />

                </div>
                <div className="flex flex-col items-center w-full mt-2 border-t border-gray-700">
                    <MainMenuLink path={'settings'} name="Settings" icon={<FontAwesomeIcon icon={faSliders} />} />
                </div>
            </div>
            <button
                className="flex items-center justify-center w-full h-16 mt-auto bg-gray-800 hover:bg-gray-700 hover:text-gray-300"
                onClick={logout}>
                <FontAwesomeIcon className="rotate-180" icon={faRightFromBracket} />
                <span className="ml-2 text-sm font-medium">Logout</span>
            </button>
        </div>
    )
}

export default MainMenu;