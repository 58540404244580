import {Button} from "@mui/material";
import {useStores} from "../../../store-context";
import {observer} from "mobx-react-lite";

const AddSitesPage = observer (() => {

    const {sites: {addSites, addSitesInput, setAddSitesInput, addSitesArr, addSitesToAutopostingQueue}} = useStores();

    return <div>
        <h1 className="font-bold text-2xl mb-4">Add sites</h1>
        <textarea rows="6" value={addSitesInput} onChange={(e) => {setAddSitesInput(e.target.value)}}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:border-blue-600 focus:border-1 focus:ring-1 focus:ring-blue-600 outline-0  mb-4"
                  placeholder="Sites List separated by comma. Example: site.com, site2.io, site3.com" />

        <div className="flex gap-4">
            <Button variant="contained" disableElevation onClick={() => {addSitesToAutopostingQueue()}}>
                Add to autoposting queue
            </Button>

            <Button variant="contained" disableElevation onClick={() => {addSites()}}>
                Add Directly
            </Button>
        </div>



        <div className="py-4">
            <span>Sites preview:</span>
            <div className="text-sm columns-2 lg:columns-3 xl:columns-4 2xl:columns-6">
                <ol className="max-w-md space-y-1 text-gray-900 list-decimal list-inside">
                    {addSitesArr.map(el => <li className="mt-0" key={el}>{el}</li>)}
                </ol>
            </div>
        </div>
    </div>;
})

export default AddSitesPage;