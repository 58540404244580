import {NavLink} from "react-router-dom";

const MainMenuLink = (props) => {
    const {path, name, icon} = props;
    return(
        <NavLink
            to={path}
            className={({isActive, isPending}) =>
                isActive
                    ? "flex items-center w-full h-12 px-3 mt-2 rounded bg-gray-700 hover:text-gray-300"
                    : isPending
                        ? "flex items-center w-full h-12 px-3 mt-2 rounded bg-gray-800 hover:text-gray-300"
                        : "flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-gray-700 hover:text-gray-300"
            }
        >
            {icon}
            <span className="ml-2 text-sm font-medium">{name}</span>
        </NavLink>
    )
}

export default MainMenuLink;