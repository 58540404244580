import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store-context";
import {useState} from "react";

import Loader from "../Loader/Loader";
import {EnhancedTableHead, getComparator, stableSort} from "../../TableUtils/TableUtils";
import {convertTimestampToDate} from "../../../utils/timeConvert";


const headCells = [
    {
        id: 'id',
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'for_id',
        disablePadding: false,
        label: 'Site ID',
    },
    {
        id: 'author_name',
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'author_email',
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'author_country',
        disablePadding: false,
        label: 'Country',
    },
    {
        id: 'created_at',
        disablePadding: false,
        label: 'Created At',
    },
    {
        id: 'content',
        disablePadding: false,
        label: 'Content',
    },
    {
        id: 'actions',
        disablePadding: true,
        label: 'Actions',
    },
];


const EnhancedTable = (props) => {
    const {comments, order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage, deleteComment} = props;
    const [dense, setDense] = useState(true);


    let rows = comments;


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );

    return (
        <Box sx={{ width: '100%' }}>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense"
            />

            <Paper sx={{ width: '100%', mb: 2 }}>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, 250, 500]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton={true}
                        showLastButton={true}
                    />


                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            numSelected={0}
                            onSelectAllClick={() => {}}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {

                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="left">{row.for_id}</TableCell>
                                        <TableCell align="left">{row.author_name }</TableCell>
                                        <TableCell align="left">{row.author_email }</TableCell>
                                        <TableCell align="left">{row.author_country}</TableCell>
                                        <TableCell align="left">{convertTimestampToDate(row.created_at)}</TableCell>
                                        <TableCell align="left">{row.content}</TableCell>
                                        <TableCell align="left" padding="none">

                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => {deleteComment(row.id)}}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 250, 500]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </Paper>
        </Box>
    );
}




export default observer(() => {

    const {comments: commentsStore} = useStores();
    const {initComments, comments} = commentsStore;

    if(comments.length === 0){
        initComments();
        return <Loader/>;
    }

    return <>
        <h1 className="font-bold text-2xl mb-4">Comments</h1>
        <EnhancedTable sites={comments} {...commentsStore} />
    </>
});