import {createContext, useContext} from "react";

export const StoreContext = createContext(null);

export const useStores = () => {
    const context = useContext(StoreContext);
    if(context === null){
        throw new Error('App is not in provider')
    }
    return context;
}