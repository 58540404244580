import {makeAutoObservable, runInAction} from "mobx";
import api from "../api/api";
import toast from "react-hot-toast";

class DashboardStoreClass {

    constructor() {
        makeAutoObservable(this);
    }

    sitesCount = null;
    commentsCount = null;
    internalOptions = null;
    refreshDataLoading = false;

    setSitesCount = (value) => {
        this.sitesCount = value;
    }
    setCommentsCount = (value) => {
        this.commentsCount = value;
    }
    setInternalOptions = (value) => {
        this.internalOptions = value;
    }


    init = () => {
        runInAction(async () => {
            const [sitesCount, commentsCount, internalOptions] = await Promise.all([api.getCountFromTable('sites'), api.getCountFromTable('comments'), api.getInternalOptions()]);
            this.setSitesCount(sitesCount);
            this.setCommentsCount(commentsCount);
            this.setInternalOptions(internalOptions);
            this.setRefreshDataLoading(false);
        })
    }

    refreshData = () => {
        this.setRefreshDataLoading(true);
        this.init();
    }

    setRefreshDataLoading = (value) => {
        this.refreshDataLoading = value;
    };

    clearFrontCache = async () => {
        const result = await api.clearFrontCache();
        if(result){
            toast.success("Success", {position: 'top-right'});
        }
    }

    recreateSitemap = async () => {
        const result = await api.recreateSitemap();
        if(result){
            toast.success("Success", {position: 'top-right'});
        }
    }

    saveInternalOptions = async () => {
        const result = await api.setInternalOptions(this.internalOptions);
        if(result){
            toast.success("Settings saved", {position: 'top-right'});
        }
    }

}

const dashboardStore = new DashboardStoreClass();

export default dashboardStore;