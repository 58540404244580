import { FileUploader } from "react-drag-drop-files";
import "./dropArea.css";

const UploadFileDragDrop = ({setFile, fileTypes}) => {

    return (
        <div>
            <FileUploader
                multiple={false}
                handleChange={setFile}
                name="file"
                types={fileTypes}
                label="Drag file here"
                classes="dropArea"
            />
        </div>
    );
}

export default UploadFileDragDrop;