import './App.css';
import LoginPage from "./components/LoginPage";

import {useStores} from "./store-context"
import {Toaster} from 'react-hot-toast';
import {observer} from "mobx-react-lite";
import {useEffect} from "react";
import DashboardRoot from "./components/Dashboard/DashboardRoot";
import MainLoader from "./components/MainLoader/MainLoader";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import SitesPage from "./components/Dashboard/SitesPage/SitesPage";
import DashboardMain from "./components/Dashboard/DashboardMain/DashboardMain";
import AddSitesPage from "./components/Dashboard/AddSitesPage/AddSitesPage";
import CommentsPage from "./components/Dashboard/CommentsPage/CommentsPage";
import ArticlesPage from "./components/Dashboard/ArticlesPage/ArticlesPage";
import ArticleEditorPage from "./components/Dashboard/ArticleEditorPage/ArticleEditorPage";
import AddArticlePage from "./components/Dashboard/AddArticlePage/AddArticlePage";
import SettingsPage from "./components/Dashboard/SettingsPage/SettingsPage";
import AutopostingSitesPage from "./components/Dashboard/AutopostingSitesPage/AutopostingSitesPage";


const AppRoot = observer(() => {

    const {user: {userAuthenticated, initUser, isLoading}} = useStores();

    useEffect(() => {
        initUser();
    }, [initUser])

    return (
        <div className="min-h-screen bg-slate-200">
            {isLoading ? <MainLoader/> : ""}
            <Toaster/>
            {userAuthenticated ? <DashboardRoot/> : <LoginPage/> }
        </div>

    );
})

const router = createBrowserRouter([
    // {
    //     path: "/",
    //     element: <AppRoot/>,
    //     errorElement: <ErrorPage />,
    // },
    {
        path: "/",
        element: <AppRoot/>,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <DashboardMain />,
            },
            {
                path: "main",
                element: <DashboardMain />,
            },
            {
                path: "sites",
                element: <SitesPage />,
            },
            {
                path: "autoposting-sites",
                element: <AutopostingSitesPage />,
            },
            {
                path: "add-sites",
                element: <AddSitesPage />,
            },
            {
                path: "comments",
                element: <CommentsPage />,
            },
            {
                path: "articles",
                element: <ArticlesPage />,
            },
            {
                path: "articles/edit/:id",
                element: <ArticleEditorPage />,
            },
            {
                path: "add-article",
                element: <AddArticlePage />,
            },
            {
                path: "settings",
                element: <SettingsPage />,
            },

        ],
    },
]);

const App = () => <RouterProvider router={router} />



export default App;
