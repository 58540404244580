import {makeAutoObservable} from "mobx";
import api from "../api/api";
import toast from "react-hot-toast";

class ArticlesStore {
    constructor() {
        makeAutoObservable(this);
    }

    articles = [];

    setArticles = (value) => {
        this.articles = value;
    }

    initArticlesList = async () => {
        const articles = await api.getArticlesList()
        if(articles){
            this.setArticles(articles);
        }
    }

    setArticlePublishState = async (article, published) => {
        const res = await api.updateArticle(article.id, {published: published ? 1 : 0});
        if(res){
            article.published = published ? 1 : 0;
            toast.success("Success", {position: 'top-right'});
        }
    }

    deleteArticle = async (article) => {
        const deleted = await api.deleteArticle(article.id);
        if(deleted){
            toast.success("Deleted", {position: 'top-right'});
            this.articles.remove(article);
        }else{
            toast.error("Error", {position: 'top-right'});
        }
    }
}

const articlesStore = new ArticlesStore();

export default articlesStore;