import {makeAutoObservable, runInAction} from "mobx";
import api from "../api/api";
import toast from "react-hot-toast";


class ArticleEditorStore{
    constructor() {
        makeAutoObservable(this);
    }

    dataLoadingProcess = true;
    error = false;

    articleData = {};

    setError = (value) => {
        this.error = value;
    }

    setDataLoadingProcess = (value) => {
        this.dataLoadingProcess = value;
    }

    setArticleData = (articleData) => {
        this.articleData = articleData
    }

    updateArticleData = (valueObject) => {
        this.articleData = {...this.articleData, ...valueObject};
    }


    loadArticleToEditor = async (id) => {
        this.setDataLoadingProcess(true);
        this.setError(false)
        const article = await api.getArticleById(id);
        if(article){
            runInAction(() => {
                this.setArticleData(article);
                this.setDataLoadingProcess(false);
            })
        }else{
            this.setError(true);
            this.setDataLoadingProcess(false);
        }
    }

    updateArticle = async () => {
        let copiedArticleData = {...this.articleData};
        delete copiedArticleData.id;
        const res = await api.updateArticle(this.articleData.id, copiedArticleData);
        if(res){
            toast.success("Success", {position: 'top-right'});
        }
    }




}

const articleEditorStore = new ArticleEditorStore();

export default articleEditorStore;