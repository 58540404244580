import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store-context";
import {useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Fuse from "fuse.js";
import {TextField} from "@mui/material";
import Loader from "../Loader/Loader";
import {EnhancedTableHead, getComparator, stableSort} from "../../TableUtils/TableUtils";
import {convertTimestampToDate} from "../../../utils/timeConvert";

const headCells = [
    {
        id: 'id',
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'url',
        disablePadding: false,
        label: 'URL',
    },
    {
        id: 'last_http_code',
        disablePadding: false,
        label: 'Code',
    },
    {
        id: 'updated_at',
        disablePadding: false,
        label: 'Updated',
    },
    {
        id: 'screenshot_updated_at',
        disablePadding: false,
        label: 'Screenshot Updated',
    },
    {
        id: 'views',
        disablePadding: false,
        label: 'Views',
    },
    {
        id: 'screenshot_path',
        disablePadding: false,
        label: 'Screenshot',
    },
    {
        id: 'actions',
        disablePadding: true,
        label: 'Actions',
    },
];


const EnhancedTable = (props) => {
    const {sites, order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage, screenshotsVisibility, setScreenshotsVisibility, deleteSite, setScreenshotVisibility} = props;
    const [dense, setDense] = useState(true);
    const [search, setSearch] = useState('');

    const fuse = new Fuse(sites, {
        includeScore: true,
        minMatchCharLength: 2,
        threshold: 0.4,
        keys: [
            "url", "id"
        ]
    });

    let rows = sites;

    if(search.length > 2){
        const searchResults = fuse.search(search);
        let rowsArr = [];
        for(let row of searchResults){
            rowsArr.push({...row.item, searchScore: row.score})
        }
        rows = rowsArr;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleChangeScreenshotsVisibility = (event) => {
        setScreenshotsVisibility(event.target.checked);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = (search.length > 2) ? rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    ) : stableSort(rows, getComparator( order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    );

    const getImagePart = (row) => {
        if(screenshotsVisibility){
            return row.screenshot_path ? <img
                src={`${process.env.REACT_APP_FILES_ENDPOINT}/${row.screenshot_path}/${row.url}.jpeg`}
                alt={`${row.url} screenshot`}
                width="440"
                height="330"
                loading="lazy"
            /> : "X"
        }
        return row.screenshot_path ? "Logo isset" : "X"

    }

    return (
        <Box sx={{ width: '100%' }}>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense"
            />

            <FormControlLabel
                control={<Switch checked={screenshotsVisibility} onChange={handleChangeScreenshotsVisibility} />}
                label="Screenshots"
            />


            <Paper sx={{ width: '100%', mb: 2 }}>
                <div className="flex w-full justify-between pt-2 pl-3 border-b">
                    <TextField id="outlined-basic" label="Search" variant="outlined" size="small" value={search} onChange={(e) => {
                        setSearch(e.target.value);
                    }} />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, 250, 500]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton={true}
                        showLastButton={true}
                    />
                </div>

                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            numSelected={0}
                            onSelectAllClick={() => {}}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {

                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="left">{row.url}</TableCell>
                                        <TableCell align="left">{row.last_http_code}</TableCell>
                                        <TableCell align="left">{convertTimestampToDate(row.updated_at) }</TableCell>
                                        <TableCell align="left">{convertTimestampToDate(row.screenshot_updated_at) }</TableCell>
                                        <TableCell align="left">{row.views}</TableCell>
                                        <TableCell align="left">
                                            {getImagePart(row)}

                                        </TableCell>
                                        <TableCell align="left" padding="none">

                                            {row.screenshot_hidden ?
                                                <Tooltip title="Make screenshot visible">
                                                    <IconButton onClick={() => {setScreenshotVisibility(row, null)}} >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip title="Hide screenshot">
                                                    <IconButton onClick={() => {setScreenshotVisibility(row, true)}}>
                                                        <VisibilityOffIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }


                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => {deleteSite(row)}}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 250, 500]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </Paper>
        </Box>
    );
}

export default observer(() => {

    const {sites: sitesStore} = useStores();
    const {initSites, sites} = sitesStore;

    if(sites.length === 0){
        initSites();
        return <Loader/>;
    }

    return <>
        <EnhancedTable sites={sites} {...sitesStore} />
    </>
});