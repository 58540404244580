import {makeAutoObservable, runInAction} from "mobx";
import api from "../api/api";

import toast from 'react-hot-toast';

class SitesStore{
    constructor() {
        makeAutoObservable(this);
    }

    sites = [];
    sitesInitialized = false;
    sitesInAutopostingQueue = [];
    sitesInAutopostingQueueInitialized = false;
    order = 'asc';
    orderBy = 'id';
    page = 0;
    rowsPerPage = 100;
    screenshotsVisibility = false;
    addSitesInput = '';
    addSitesArr = [];

    setSites(value){
        this.sites = value;
    }

    setSitesInitialized(value){
        this.sitesInitialized = value;
    }

    setSitesInAutopostingQueue(value){
        this.sitesInAutopostingQueue = value;
    }
    setSitesInAutopostingQueueInitialized(value){
        this.sitesInAutopostingQueueInitialized = value;
    }

    initSites = async () => {
        const sites = await api.getSitesList();
        if(sites){
            this.setSites(sites);
            this.setSitesInitialized(true);
        }
    }

    initSitesInAutopostingQueue = async () => {
        const sites = await api.getSitesFromAutopostingQueue();
        if(sites){
            this.setSitesInAutopostingQueue(sites);
            this.setSitesInAutopostingQueueInitialized(true);
        }
    }

    deleteSiteFromAutopostingQueue = async (site) => {
        const siteDeleted = await api.deleteSiteFromAutopostingQueue(site.id);
        if(siteDeleted){
            toast.success("Deleted", {position: 'top-right'});
            this.sitesInAutopostingQueue.remove(site);
            // this.initSitesInAutopostingQueue();
        }else{
            toast.error("Error", {position: 'top-right'});
        }
    }

    setOrder = (value) => {
        this.order = value;
    }

    setOrderBy = (value) => {
        this.orderBy = value;
    }

    setPage = (value) => {
        this.page = value;
    }

    setRowsPerPage = (value) => {
        this.rowsPerPage = value;
    }

    setScreenshotsVisibility = (value) => {
        this.screenshotsVisibility = value;
    }

    deleteSite = async (site) => {
        const siteDeleted = await api.deleteSite(site.id);
        if(siteDeleted){
            toast.success("Deleted", {position: 'top-right'});
            this.sites.remove(site);
        }else{
            toast.error("Error", {position: 'top-right'});
        }
    }

    setScreenshotVisibility = async (site, visibility) => {
        const res = await api.updateSite(+site.id, {screenshot_hidden: visibility});
        if(res){
            site.screenshot_hidden = visibility;
            toast.success("Success", {position: 'top-right'});
        }else{
            toast.error("Error", {position: 'top-right'});
        }
    }

    setAddSitesInput = (value) => {
        runInAction(() => {
            this.addSitesInput = value;
            this.setAddSitesArr(value.toLowerCase().replaceAll(' ', '').replaceAll('"', '').split(',').filter(el => el));
        })
    }

    setAddSitesArr = (value) => {
        this.addSitesArr = value
    }

    addSites = async () => {
        const res = await api.addSites(this.addSitesArr);
        if(res){
            toast.success("Added sites", {position: 'top-right'});
            this.setAddSitesInput('');
            //init sites with new added
            await this.initSites();
        }
    }

    addSitesToAutopostingQueue = async () => {
        const res = await api.addSitesToAutoposting(this.addSitesArr);
        if(res){
            toast.success("Added sites to Autoposting", {position: 'top-right'});
            this.setAddSitesInput('');
            //init sites with new added
            await this.initSitesInAutopostingQueue();
        }
    }

    setAllSitesUrlsToLowerCase = async () => {
        const res = await api.setAllSitesUrlsToLowerCase();
        if(res){
            toast.success("success", {position: 'top-right'});
            await this.initSites();
        }
    }

}

const sitesStore = new SitesStore();
export default sitesStore;