import {useStores} from "../../../store-context";
import {observer} from "mobx-react-lite";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Loader from "../Loader/Loader";
import {convertMsToWords, convertTimestampAgoToWords, convertTimestampToDate} from "../../../utils/timeConvert";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faEraser, faSitemap} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const DashboardMain = observer(() => {
    const {
        dashboard: {
            init,
            sitesCount,
            commentsCount,
            internalOptions,
            refreshDataLoading,
            refreshData,
            clearFrontCache,
            recreateSitemap
        },
        sites:{
            setAllSitesUrlsToLowerCase
        }
    } = useStores();

    if (sitesCount === null) {
        init();
        return <Loader/>
    }

    return (
        <>
            <h1 className="font-bold text-2xl mb-4">Dashboard</h1>

            <div className="absolute top-5 right-5">
                <Tooltip title="Refresh Data">
                    <IconButton aria-label="Refresh Data" size="small" onClick={refreshData}>
                        <FontAwesomeIcon icon={faArrowsRotate} className={refreshDataLoading? "text-slate-400" : "text-blue-600" } />
                    </IconButton>
                </Tooltip>
            </div>


            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">
                                Sites
                            </TableCell>
                            <TableCell align="right">{sitesCount}</TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">
                                Comments
                            </TableCell>
                            <TableCell align="right">{commentsCount}</TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">
                                Sitemap Generated At
                            </TableCell>
                            <TableCell
                                align="right">{internalOptions.sitemap_generated_time ? `${convertTimestampToDate(internalOptions.sitemap_generated_time)} (${convertTimestampAgoToWords(internalOptions.sitemap_generated_time)})` : ""}</TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">
                                Sitemap regenerate interval
                            </TableCell>
                            <TableCell
                                align="right">{internalOptions.regenerate_sitemap_interval ? convertMsToWords(+internalOptions.regenerate_sitemap_interval) : ""}</TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row">
                                Autoposting Enabled
                            </TableCell>
                            <TableCell
                                align="right">{+(internalOptions.autoposting_enabled) ? "true" : "false"}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="flex items-center pt-4 gap-2">
                <Button variant="outlined" onClick={recreateSitemap} startIcon={<FontAwesomeIcon icon={faSitemap}/>}>
                    Regenerate sitemap
                </Button>

                <Button variant="outlined" onClick={clearFrontCache} startIcon={<FontAwesomeIcon icon={faEraser}/>}>
                    Clear Front Cache
                </Button>

                <Button variant="outlined" onClick={setAllSitesUrlsToLowerCase} startIcon={<FontAwesomeIcon icon={faEraser}/>}>
                    Set all urls to lowercase
                </Button>
            </div>

        </>
    );
});

export default DashboardMain;